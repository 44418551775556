import { Heading, Text } from './..';

interface Props {
    className?: string;
    userImage: string;
    userWalletConnectionText?: string;
    title: string;
    description: string;
    breakline?: 'true' | 'false';
}

export default function UserProfile({
    title,
    description,
    userImage,
    userWalletConnectionText,
    ...props
}: Props) {
    return (
        <div
            {...props}
            className={`${props.className} flex flex-col items-start`}
        >
            <Text size="text4xl" as="p" className="bg-gradient bg-clip-text">
                {userImage}
            </Text>
            <Heading
                size="textxl"
                as="p"
                className="!font-normal leading-[26px] !text-white-a700_cc"
            >
                <span className="text-white-a700_cc">{title} </span>
                {props.breakline == 'true' && <br />}
                <span className="bg-gradient bg-clip-text font-medium text-transparent">
                    {description}
                </span>
            </Heading>
        </div>
    );
}
