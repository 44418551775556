import { Button, Heading, Text } from '@/Components';
import Background from '@/Components/Background';
import UserProfile from '@/Components/UserProfile';
import HeaderLayout from '@/Layouts/HeaderLayout';
import Head from '@/Components/Head';

export default function Welcome() {
    return (
        <HeaderLayout>
            <Head title="Welcome" />
            <Background src="/images/img_group_1.png" />
            <div className="my-auto flex h-full w-full flex-col items-start justify-items-stretch sm:py-8">
                <div className="my-auto flex flex-col gap-[54px] py-4 sm:gap-[27px]">
                    <div className="flex flex-col gap-11 self-stretch">
                        <div className="flex flex-col items-start">
                            <Text
                                as="p"
                                className="bg-gradient bg-clip-text md:text-[22px]"
                            >
                                The New Era of the Internet demands a reputation
                            </Text>
                            <Heading
                                size="text6xl"
                                as="h1"
                                className="max-w-4xl text-[72px] not-italic lg:py-5 lg:text-6xl md:w-full md:max-w-2xl"
                            >
                                Claim your Decentralized Identity right now
                            </Heading>
                        </div>
                        <div className="flex gap-20 lg:mr-0 md:mr-0 md:flex-col md:gap-6">
                            <UserProfile
                                userImage="1."
                                className=""
                                title="Connect your"
                                description="Polkadot Wallet"
                                breakline="true"
                            />
                            <div className="mb-5 h-[80px] w-px bg-gradient md:mb-0 md:h-px md:w-64 sm:w-full" />
                            <UserProfile
                                userImage="2."
                                className=""
                                title="Fill the form with your"
                                description="Basic Information"
                                breakline="true"
                            />
                            <div className="mb-5 h-[80px] w-px bg-gradient md:mb-0 md:h-px md:w-64 sm:w-full" />
                            <UserProfile
                                userImage="3."
                                className=""
                                title="Get your"
                                description="Decentralized Identity"
                                breakline="true"
                            />
                        </div>
                    </div>
                    <Button
                        elementType="a"
                        variant="gradient"
                        shape="round"
                        color="pink_500_pink_A100"
                        className="w-fit min-w-[282px] p-4 lg:text-[17px] sm:w-full"
                        href={route('connect-wallet')}
                    >
                        Start now
                    </Button>
                </div>
            </div>
        </HeaderLayout>
    );
}
