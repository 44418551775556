interface Props {
    src: string;
    className?: string;
    brightness?: string;
    contrast?: string;
}

export default function Background({
    brightness = '0.2',
    contrast = '90',
    className = '',
    src,
}: Props) {
    return (
        <div
            style={{ backgroundImage: `url(${src})` }}
            className={`fixed bottom-0 top-0 z-[-1] h-[100vh] w-full bg-cover bg-no-repeat object-cover after:fixed after:box-content after:h-[100vh] after:w-full after:bg-neutral-900 after:opacity-50 brightness-[${brightness}] contrast-[${contrast}%] ${className}`}
        />
    );
}
